<template>
  <div>    
    <div class="vx-row">
      <div class="vx-col w-full  mt-8">
        <vx-card>
          <div class="card-title">
            <h2>Available Reports</h2>            
          </div> 
          <div class="report-list">
            <ul>
              <li>
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Call Detail Report</h3>
                  <p>This report will show the call information from your campaigns. Use this report to understand the details of each call during this period. The date range is set to one week by default.</p>
                  <div class="base-btn"><vs-button color="primary" type="filled" :to="'/view-report/uid/'+$route.params.id+'/CallDetailReport'" >{{ LabelConstant.buttonLabelRunCallDetailReport }}</vs-button></div>
                </div>
              </li>
              <li>
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3> Campaign Summary Report</h3>
                  <p>This report will show the performance of each of your call tracking campaigns. Use this report to see the performance of each of your campaigns. The date range is set to one week by default.</p>
                  <div class="base-btn"><vs-button color="primary" type="filled" :to="'/view-report/uid/'+$route.params.id+'/CampaignSummaryReport'" >{{ LabelConstant.buttonLabelRunCampaignSummaryReport }}</vs-button></div>
                </div>
              </li>
                 <li>
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Call Volume Report</h3>
                  <p>This report will show the call volume of your campaigns. Use this report to understand which days and hours of the day are producing the highest volume of calls. The date range is set to one week by default.</p>
                  <div class="base-btn"><vs-button color="primary" type="filled" :to="'/view-report/uid/'+$route.params.id+'/CallVolumeReport'">{{ LabelConstant.buttonLabelRunCallVolumeReport }}</vs-button></div>
                </div>
              </li>
            
              <li>
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Call Tracking Raw Data Report</h3>
                  <p>This report will show raw call tracking data for your campaigns. Use this report for further analysis of your inbound call activity. The date range is set to one week by default.</p>
                  <div class="base-btn"><vs-button color="primary" type="filled" :to="'/view-report/uid/'+$route.params.id+'/CallTrackingRawDataReport'" >{{ LabelConstant.buttonLabelRunCallTrackingRawDataReport }}</vs-button></div>
                </div>
              </li>
              <li v-if="this.$store.state.showCallTrackingBillingSummaryForResellers">
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Call Tracking Billing Summary Report for Resellers</h3>
                  <p>This report will show you consolidated call tracking billing information for all of your customers. Use this report for further analysis of your call tracking adoption and spend. The date range is set to one week by default.</p>
                  <div class="base-btn"><vs-button color="primary" type="filled" :to="'/view-report/uid/'+$route.params.id+'/CallTrackingBillingSummaryReportforResellers'" >{{ LabelConstant.buttonLabelRunCallTrackingBillingSummaryReport }}</vs-button></div>
                </div>
              </li>           
            </ul>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

export default {
  components: {
    VxCard  
  },
};
</script>
